import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2024/Nobagday/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2024/Nobagday/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2024/Nobagday/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/2024/Nobagday/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const NoBagDay2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Nobagday6-8/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Nobagday6-8/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Nobagday6-8/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Nobagday6-8/4.webp`;
   

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        }

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                  NO BAG DAY
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 6-8      Date: : 30 September 2024
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “With pure hearts and beaming smiles, our young learners gathered to celebrate the guiding stars in their lives—their cherished teachers.””

                                <br />
                            </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    National Public School, Yeshwanthpur, recently organised a series of Bagless Days for
                                    students of Classes 6 to 8, aiming to enhance experiential learning and creativity. During
                                    these days, students engaged in a variety of hands-on activities, including science
                                    experiments, art and craft workshops, interactive storytelling, panel discussions, math
                                    games and environmental awareness projects. By eliminating the need for textbooks and
                                    traditional supplies, students were able to fully immerse themselves in the learning
                                    process, fostering collaboration and critical thinking skills while enjoying a fun and relaxed
                                    atmosphere.
                                    <br></br>
                                    The Bagless Days proved to be highly beneficial, increasing student engagement and motivation. The interactive format allowed for deeper understanding of concepts and encouraged teamwork, helping students build strong interpersonal skills. Students were also able to connect classroom knowledge with real-world applications, enhancing their ability to think critically about the subjects they explored. Additionally, students reported feeling more excited about learning, as they were able to explore subjects in a dynamic and enjoyable manner. The initiative also allowed the teachers to observe students' strengths and interests in a new light, paving the way for personalised learning opportunities. Overall, the initiative successfully created an innovative learning environment, leaving students eager for more opportunities to explore subjects beyond conventional classroom settings.
                                    <br></br>


                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Embracing the traditions, spreading the love, and creating unforgettable memories."
                                <br />

                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default NoBagDay2024;